<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <a href="#" class="mb-12">
        <img alt="Logo" src="media/logos/logo1.png" class="h-45px" />
      </a>
      <div class="bg-white rounded shadow-sm p-10 p-lg-15 w-100 w-md-75">
        <div class="mb-10 text-center">
          <h1 class="text-dark mb-3">
            Create an Account
          </h1>
          <div class="text-gray-400 fw-bold fs-4">
            Already have an account?

            <router-link to="/sign-in" class="link-primary fw-bolder">
              Sign in here
            </router-link>
          </div>
        </div>
        <Form @submit="handleSubmit" :validation-schema="validationSchema">
          <div class="row">
            <div class="col-12 col-xl-6">
              <div class="row mb-5">
                <div class="col-12 col-sm-6">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder="First Name*"
                    name="first_name"
                    v-model="user.first_name"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="first_name" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder="Last Name*"
                    name="last_name"
                    v-model="user.last_name"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="last_name" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder="Phone +45 1234567"
                    name="phone"
                    v-model="user.phone"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="phone" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="email"
                    placeholder="Email"
                    name="email"
                    v-model="user.email"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-3">
                  <CountriesDropdown
                    :name="'country_id'"
                    @change="
                      id => {
                        user.country_id = id;
                      }
                    "
                  ></CountriesDropdown>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="country_id" />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-3">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder="City"
                    name="city"
                    v-model="user.city"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="city" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <Field
                    @input="passwordStrength"
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    placeholder="Password"
                    name="password"
                    v-model="user.password"
                    autocomplete="off"
                  />
                  <div class="d-flex align-items-center mb-3 mt-3">
                    <div
                      :class="greenBars >= 1 ? 'bg-active-success' : ''"
                      class="flex-grow-1 bg-secondary rounded h-5px me-2 active"
                    ></div>
                    <div
                      :class="greenBars >= 2 ? 'bg-active-success' : ''"
                      class="flex-grow-1 bg-secondary h-5px me-2 active"
                    ></div>
                    <div
                      :class="greenBars == 3 ? 'bg-active-success' : ''"
                      class="flex-grow-1 bg-secondary rounded h-5px me-2 active"
                    ></div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div class="text-muted">
                    Use 8 or more characters with a mix of letters and numbers.
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="password"
                    placeholder="Confirm password"
                    name="confirm_password"
                    v-model="user.confirm_password"
                    autocomplete="off"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="confirm_password" />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <Field
                    class="form-control form-control-lg form-control-solid"
                    type="textarea"
                    rows="2"
                    as="textarea"
                    placeholder="Tell us more about yourself"
                    name="about_me"
                    v-model="user.about_me"
                    autocomplete="off"
                  />
                </div>
                <div class="col-12 mt-3">
                  <input
                    type="file"
                    ref="fileinput"
                    style="display: none"
                    @input="handleFileUpload"
                  />
                  <div
                    class=" d-flex justify-content-between align-items-center"
                  >
                    <UploadCVButton @click="fileinput.click()"></UploadCVButton>
                    <div class="overflow-control">
                      <span class="form-text" v-if="user.file == null"
                        >Allowed file format: .doc, .docx, .pdf</span
                      >
                      <div class="d-flex flex-row align-items-center" v-else>
                        <span class="form-text pe-2">{{ user.file.name }}</span>
                        <DeleteFileButton @click="clearFile"></DeleteFileButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12  d-flex justify-content-center flex-column align-items-center col-xl-6 mt-10 mt-xl-0"
            >
              <label class="d-flex flex-stack mb-5 cursor-pointer w-100 w-md-75"
                ><span class="d-flex align-items-center me-2"
                  ><span class="symbol symbol-50px me-6"
                    ><span class="symbol-label">
                      <span class="svg-icon svg-icon-light-primary svg-icon-2x"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z M10.875,15.75 C11.1145833,15.75 11.3541667,15.6541667 11.5458333,15.4625 L15.3791667,11.6291667 C15.7625,11.2458333 15.7625,10.6708333 15.3791667,10.2875 C14.9958333,9.90416667 14.4208333,9.90416667 14.0375,10.2875 L10.875,13.45 L9.62916667,12.2041667 C9.29375,11.8208333 8.67083333,11.8208333 8.2875,12.2041667 C7.90416667,12.5875 7.90416667,13.1625 8.2875,13.5458333 L10.2041667,15.4625 C10.3958333,15.6541667 10.6354167,15.75 10.875,15.75 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                              fill="#000000"
                            />
                          </g></svg
                        ><!--end::Svg Icon--></span
                      ></span
                    ></span
                  ><span class="d-flex flex-column"
                    ><span
                      class="fw-bolder text-gray-800 text-hover-primary fs-7 fs-sm-6 fs-md-5"
                      >I can/will issue an invoice</span
                    ></span
                  ></span
                ><span class="form-check form-check-custom form-check-solid"
                  ><input
                    class="form-check-input"
                    type="radio"
                    name="issues_invoice"
                    v-model="user.issues_invoice"
                    value="1"/></span
              ></label>
              <label class="d-flex flex-stack mb-5 cursor-pointer w-100 w-md-75"
                ><span class="d-flex align-items-center me-2"
                  ><span class="symbol symbol-50px me-6"
                    ><span class="symbol-label"
                      ><span class="svg-icon svg-icon-lignt-primary svg-icon-2x"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <rect
                              fill="#000000"
                              x="9"
                              y="12"
                              width="6"
                              height="2"
                              rx="1"
                            />
                          </g></svg></span></span></span
                  ><span class="d-flex flex-column"
                    ><span
                      class="fw-bolder text-gray-800 text-hover-primary fs-7 fs-sm-6 fs-md-5"
                      >I cannot issue invoices</span
                    ></span
                  ></span
                ><span class="form-check form-check-custom form-check-solid"
                  ><input
                    class="form-check-input"
                    type="radio"
                    v-model="user.issues_invoice"
                    name="issues_invoice"
                    value="0"/></span
              ></label>
              <Field
                v-if="user.issues_invoice == 1 && !null"
                class="form-control form-control-lg form-control-solid w-100 w-md-75"
                type="text"
                placeholder="Tax ID"
                :disabled="user.issues_invoice == 0"
                name="tax_id"
                @input="taxIdError = false"
                v-model="user.tax_id"
                autocomplete="off"
              />
              <input
                v-else
                class="form-control form-control-lg form-control-solid w-100 w-md-75"
                style="visibility:hidden"
              />
              <div class="fv-plugins-message-container" v-if="taxIdError">
                <div class="fv-help-block">
                  <span role="alert">Tax ID is a required field</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex align-items-center justify-content-end me-4 mt-5 mt-xl-0"
          >
            <a href="/privacy-policy" target="_blank" class="me-2">
              Privacy Policy
            </a>
            &
            <a href="/privacy-notice" target="_blank" class="ms-2 me-8">
              Privacy Notice
            </a>
            <button class="btn btn-lg btn-success" :disabled="isLoading">
              <div
                class="spinner-border spinner-border-sm"
                v-if="isLoading"
                role="status"
              >
                <span class="visually-hidden"></span>
              </div>
              Register
            </button>
            <VueRecaptcha
              :sitekey="siteKey"
              :loadRecaptchaScript="true"
              ref="recaptcha"
              size="invisible"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              @render="onCaptchaRender"
            >
            </VueRecaptcha>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import CountriesDropdown from "@/components/dropdown/VeeCountriesDropdown.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter, useRoute } from "vue-router";
import UploadCVButton from "@/views/auth/misc/UploadCVButton.vue";
import DeleteFileButton from "@/views/auth/misc/DeleteFileButton.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
    CountriesDropdown,
    UploadCVButton,
    DeleteFileButton,
    VueRecaptcha
  },
  setup() {
    const recaptcha = ref(null)
    const siteKey = ref(process.env.RECAPTCHA_SITE_KEY ? process.env.RECAPTCHA_SITE_KEY : '6LfdrqwfAAAAAHKaV3GpZ_2tS0zxFQr_Hbmk2ZmY');

    const store = useStore();
    const isLoading = computed(
      () => store.getters["RegistrationModule/isLoading"]
    );
    const fileinput = ref();
    const user = reactive({
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      phone: "",
      email: "",
      city: "",
      country_id: "",
      issues_invoice: null,
      tax_id: "",
      recaptcha: "",
      about_me: "",
      invited_by_id: null,
      file: null
    });
    onMounted(() => {
      if (store.getters.isUserAuthenticated) {
        useRouter().push("/");
      }
      if(useRoute().query.refID) {
        user.invited_by_id = parseInt(useRoute().query.refID);
        useRouter().replace({ query: {} })
      }
    });
    const taxIdError = ref(false);
    const greenBars = ref(0);
    const validationSchema = Yup.object().shape({
      first_name: Yup.string()
        .required()
        .label("First name"),
      last_name: Yup.string()
        .required()
        .label("Last name"),
      country_id: Yup.string()
        .required()
        .label("Country"),
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is a required field"),
      password: Yup.string()
        .required("Password is a required field")
        .min(8, "Passwords must be at least 8 characters long")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/,
          "Passwords must contain one uppercase, one lowercase , one number"
        ),
      confirm_password: Yup.string()
        .required("Passwords do not match")
        .oneOf([Yup.ref("password")], "Passwords do not match")
    });
    function passwordStrength() {
      const strongPassword = new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8}))"
      );
      const mediumPassword = new RegExp("(?=.*[0-9])(?=.*[a-z]).{8}$");
      if (strongPassword.test(user.password)) {
        greenBars.value = 3;
      } else if (mediumPassword.test(user.password)) {
        greenBars.value = 2;
      } else if (user.password == "") {
        greenBars.value = 0;
      } else {
        greenBars.value = 1;
      }
    }

    function onCaptchaVerified(recaptchaToken) {
      user.recaptcha = recaptchaToken
    }

    function onCaptchaExpired() {
      recaptcha.value.reset();
    }

    function onCaptchaRender() {
      recaptcha.value.execute();
    }
    const allowedFileTypes = ref([
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ])
    const clearFile = () => {
      user.file = null;
      fileinput.value.value = "";
    }
    const handleFileUpload = event => {
      if(event.target.files && event.target.files[0]) {
        const uploadedFile = event.target.files[0];
        if(allowedFileTypes.value.includes(uploadedFile.type)) {
          user.file = uploadedFile;
          Swal.fire({
            icon: "success",
            toast: "true",
            title: "File uploaded successfully",
            timer: 1000,
            position: "top-right",
            showConfirmButton: false
          })
        } else {
          user.file = null;
          Swal.fire({
            icon: "error",
            title: "Invalid file type",
            timer: 1000,
            position: "center",
            showConfirmButton: false
          })
        }
      }
    }

    function handleSubmit() {
      if (
          user.issues_invoice == null ||
          user.issues_invoice == 0 ||
          user.tax_id != ""
      ) {
        store.dispatch("RegistrationModule/createNew", user).then(() => {
          recaptcha.value.reset();
          recaptcha.value.execute();
        });
      } else if (user.tax_id == "") {
        taxIdError.value = true;
      }
    }

    return {
      user,
      taxIdError,
      passwordStrength,
      onCaptchaVerified,
      handleFileUpload,
      handleSubmit,
      fileinput,
      clearFile,
      isLoading,
      recaptcha,
      greenBars,
      onCaptchaRender,
      onCaptchaExpired,
      validationSchema,
      siteKey
    };
  }
});
</script>

<style scoped>
::v-deep .el-input__inner {
  min-height: calc(1.5em + 1.65rem + 2px) !important;
}
.overflow-control {
  max-width: 30ch;
  word-break: break-all;
}
</style>
